import { createApp } from 'vue';
import App from './App.vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import './registerServiceWorker';
import vue3GoogleLogin from 'vue3-google-login'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCryptojs from 'vue-cryptojs'

import store from './store/index'
import router from './router'


createApp(App).use(router).use(store).use(autoAnimatePlugin).use(VueCryptojs).use(vue3GoogleLogin, {
    clientId: '523054575759-6dchpj56goc8ecnn2sshtl6f853k5tel.apps.googleusercontent.com',
}).mount('#app');