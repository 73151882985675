import { createStore } from 'vuex';

export default createStore({

    state: {
        display_login_prompt  : false,
        display_payment_prompt: false,
        display_success_prompt: false,
        display_dropdown_login_prompt: false,
        user_wants_to_upgrade: false,

        selected_gender: 0,
        user_details: {
            logged_in: false,
            email: "",
            platform: "",
            platform_id: "",
            notifications: [],
            sql_id: 0,
            raw_response: {},
            name: "",
            url_pic: "",
            google_response: {},
            subscribed: false,
        },
        
    },

    mutations: {

        display_or_hide_upgrade_modal_prompt(state: any, n: boolean){
            state.user_wants_to_upgrade = n;
        },
        
        display_or_hide_login_prompt(state: any, n: boolean) {
            state.display_login_prompt = n;
        },
        display_or_hide_dropdown_login_prompt(state: any, n: boolean) {
            state.display_dropdown_login_prompt = n;
        },
        display_or_hide_payment_prompt(state: any, n: boolean) {
            state.display_payment_prompt = n;
        },
        display_or_hide_success_prompt(state: any, n: boolean) {
            state.display_success_prompt = n;
        },

        update_notifications_on_user(state: any, n: any){
            state.user_details.notifications = n;
        },

        update_user_details(state: any, n: any){
            state.user_details = n;
        },

        update_selected_gender(state: any, n: number) {
            state.selected_gender = n;
        }
    },
    getters: {
    },
    actions: {
    },
    modules: {
    }
})
